import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enUS } from 'date-fns/locale/en-US';
import { pl } from 'date-fns/locale/pl';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettingsStore } from 'src/store/settings-store';

export const LocalizationProvider = ({ children }: { children: ReactNode }) => {
  const settingsLanguage = useSettingsStore(state => state.language);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (settingsLanguage === i18n.language) return;
    i18n.changeLanguage(settingsLanguage);
  }, [settingsLanguage, i18n]);

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={settingsLanguage === 'en' ? enUS : pl}>
      {children}
    </MuiLocalizationProvider>
  );
};
