const applications = [
  {
    logo: {
      light: '/static/marketplace/sos-logo-light.svg',
      dark: '/static/marketplace/sos-logo-dark.svg',
    },
    name: 'SoS',
    description: 'marketplace:applications.sos.description',
    href: {
      dev: {
        site: 'https://test.admin.signalos.io/',
        tenant: 'https://test.admin.signalos.io/',
      },
      prod: {
        site: 'https://admin.signalos.io/',
        tenant: 'https://admin.signalos.io/',
      },
    },
    isDisabled: false,
  },
  {
    logo: {
      light: '/static/marketplace/parkiza-logo-light.svg',
      dark: '/static/marketplace/parkiza-logo-dark.svg',
    },
    name: 'Parkiza',
    description: 'marketplace:applications.parkiza.description',
    href: {
      dev: {
        site: 'https://test-admin.parkiza.com/',
        tenant: 'https://test-admin.parkiza.com/',
      },
      prod: {
        site: 'https://admin.parkiza.com/',
        tenant: 'https://admin.parkiza.com/',
      },
    },
    isDisabled: false,
  },
  {
    logo: {
      light: '/static/marketplace/wayin-logo-light.svg',
      dark: '/static/marketplace/wayin-logo-dark.svg',
    },
    name: 'WayIn',
    description: 'marketplace:applications.wayin.description',
    href: {
      dev: {
        site: 'https://test-site.wayin.io',
        tenant: 'https://test-admin.wayin.io',
      },
      prod: {
        site: 'https://site.wayin.io',
        tenant: 'https://admin.wayin.io',
      },
    },
    isDisabled: false,
  },
  {
    logo: {
      light: '/static/marketplace/inbin-logo-light.svg',
      dark: '/static/marketplace/inbin-logo-dark.svg',
    },
    name: 'InBin',
    description: 'marketplace:applications.inbin.description',
    href: {
      dev: {
        site: 'https://test-admin.inbin.tech/',
        tenant: 'https://test-admin.inbin.tech/',
      },
      prod: {
        site: 'https://admin.inbin.tech/',
        tenant: 'https://admin.inbin.tech/',
      },
    },
    isDisabled: false,
  },
  {
    logo: {
      light: '/static/marketplace/visitors-logo-light.svg',
      dark: '/static/marketplace/visitors-logo-dark.svg',
    },
    name: 'Visitors',
    description: 'marketplace:applications.visitors.description',
    href: {
      dev: {
        site: 'https://test-admin.visitors.signalos.io/',
        tenant: 'https://test-admin.visitors.signalos.io/',
      },
      prod: {
        site: 'https://admin.visitors.signalos.io/',
        tenant: 'https://admin.visitors.signalos.io/',
      },
    },
    isDisabled: false,
  },
  {
    logo: {
      light: '/static/marketplace/desk-booking-logo-light.svg',
      dark: '/static/marketplace/desk-booking-logo-dark.svg',
    },
    name: 'Desk Booking',
    description: 'marketplace:applications.deskBooking.description',
    href: {
      dev: {
        site: '',
        tenant: '',
      },
      prod: {
        site: '',
        tenant: '',
      },
    },
    isDisabled: true,
  },
] as const;

export default applications;
